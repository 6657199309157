<template>
    <div class="preview_area show_sidebar" v-if="modelValue" :class="{ active : modelValue }">
        <div class="global_setting">
            <button class="close_btn modal-window-close-button" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
            <ul class="tabs_btn">
                <li :class="tab === 'share' ? 'active' : ''" @click="tab = 'share'">Share Link</li>
                <li :class="tab === 'rewards' ? 'active' : ''" @click="tab = 'rewards'">Bonus Rewards </li>
                <li :class="tab === 'faq' ? 'active' : ''" @click="tab = 'faq'">Program FAQ</li>
            </ul>
            <div class="tabs_content">
                <button type="button" class="toggle_bar" @click="toggleSectionbar"><img src="@/assets/images/bar-left.svg" alt=""></button>
                <button type="button" class="reward_balance" @click="show_balance = !show_balance;" v-if="tab === 'share'"><img src="@/assets/images/thrive-dollar.svg" alt=""></button>
                <div class="content_area">
                    <div class="section_content w-100" v-if="tab === 'share'">
                        <div class="top_area">
                            <div class="headline">
                                <h2>Give 30-Days To Another Coach And Get $20 If They Join</h2>
                                <h4>Earn bonus rewards as you refer more coaches</h4>
                            </div>
                            <div class="referal_link">
                                <h5>Share your unique referal link</h5>
                                <div class="link_area">
                                    <input type="text" :value="link.link">
                                    <div class="copy_btn" @click="copyLink(link.link)"><i class="far fa-copy"></i><span>Copy Link</span></div>
                                </div>
                                <ul class="btn_list">
                                    <li>
                                        <a :href="`https://www.facebook.com/sharer/sharer.php?u=${link.link}`" target="_blank"><i class="fab fa-facebook-f"></i>Share</a>
                                    </li>
                                    <li>
                                        <a :href="`https://twitter.com/intent/tweet?url=${link.link}`" target="_blank"><i class="fab fa-twitter"></i>Share</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="acc_balance" :class="{'show' : show_balance}">
                                <h4><img src="@/assets/images/logo.svg" alt="" /> Account Balance</h4>
                                <h1><span>Thrive Dollars:</span>${{ link.account_balance ? link.account_balance : 0 }}</h1>
                            </div>
                            <div class="other_info">
                                <h4>Your Referral History</h4>
                                <ul class="btn_list">
                                    <li><a>{{ link.clicks }} Link Clicks</a></li>
                                    <li><a>{{ link.trials_count ? link.trials_count : 0 }} Trials</a></li>
                                    <li><a>{{ link.billed_count ? link.billed_count : 0 }} Paid Accounts</a></li>
                                </ul>
                                <h4>You Rock {{ user.fname }}!</h4>
                            </div>
                        </div>
                        <div class="result_wpr" v-if="histories.total">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Clicked link</th>
                                        <th>Email verified</th>
                                        <th>Started trial</th>
                                        <th>Rebilled on a plan</th>
                                        <th>Thrive Dollars</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(history, h) of histories.data" :key="h">
                                        <td>
                                            <div class="email_wpr">{{ history.user ? history.user.email : (history.registration ? history.registration.email : '') }}</div>
                                        </td>
                                        <td>
                                            <i v-if="history.registration" class="fas fa-check"></i>
                                            <span v-else>-</span>
                                        </td>
                                        <td>
                                            <i v-if="history.is_trial" class="fas fa-check"></i>
                                            <span v-else>-</span>
                                        </td>
                                        <td>
                                            <i v-if="history.is_billed" class="fas fa-check"></i>
                                            <span v-else>-</span>
                                        </td>
                                        <td>${{ history.earned_amount }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table_footer" v-show="histories.total">
                            <ul>
                                <li>{{ histories.from }} - {{ histories.to }} of {{ histories.total }}</li>
                            </ul>
                        </div>
                        <div class="pagination" v-show="histories.total">
                            <pagination v-model="historyParams.page" :pages="histories.last_page" @update:modelValue="handlePagination" />
                        </div>
                    </div>
                    <div class="content_row" v-if="tab === 'rewards'">
                        <div class="section_content">
                            <div class="headline">
                                <h2><img src="@/assets/images/gift.svg">Bonus Rewards</h2>
                                <h4>Earn rewards for every trial you refer</h4>
                            </div>
                            <div class="other_info">
                                <h4>Your Referral History</h4>
                                <ul class="btn_list">
                                    <li><a>{{ histories.total }} Referrals</a></li>
                                    <li><a>Rank #{{ link.rank }}</a></li>
                                </ul>
                            </div>
                            <div class="reward_list">
                                <ul>
                                    <li>
                                        <div class="card_info">
                                            <p>Refer 5 Qualified Trials: Get an extra $25 Thrive Dollars when you refer 5 coaches who start a Thrive Coach trial and complete at least 25 days. Each trial started will be orange. After 25 days the ribbon will turn blue and count toward your bonus. When your card is filled your $25 bonus will be added to your account and you will get your $25 badge.</p>
                                            <img src="@/assets/images/logo.svg" alt=""/>
                                        </div>
                                        <ul class="badges">
                                            <li v-for="index in 5" :key="index" :class="{ 'orange-badge': link.joined >= index && link.active < index, 'blue-badge': link.active >= index }"><i class="fas fa-award"></i></li>
                                            <li class="earned-dollar" v-if="link.active >= 5"><i class="fas fa-gift"></i> $25</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <div class="card_info">
                                            <p>Refer 25 Qualified Trials: get an extra $100 Thrive Dollars when you refer 25 coaches who start a Thrive Coach trial and complete at least 25 days. Each trial started will be orange. After 25 days the ribbon will turn blue and count toward your bonus. When your card is filled your $100 bonus will be added to your account and you will get your $100 badge.</p>
                                            <img src="@/assets/images/logo.svg" alt=""/>
                                        </div>
                                        <ul class="badges">
                                            <li v-for="index in 25" :key="index" :class="{ 'orange-badge': link.joined >= index && link.active < index && link.joined > 5, 'blue-badge': link.active >= index && link.joined > 5 }"><i class="fas fa-award"></i></li>
                                            <li class="earned-dollar" v-if="link.active >= 25"><i class="fas fa-gift"></i> $100</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <div class="card_info">
                                            <p>Refer 50 Qualified Trials: get an extra $200 Thrive Dollars when you refer 50 coaches who start a Thrive Coach trial and complete at least 25 days. Each trial started will be orange. After 25 days the ribbon will turn blue and count toward your bonus. When your card is filled your $200 bonus will be added to your account and you will get your $200 badge.</p>
                                            <img src="@/assets/images/logo.svg" alt=""/>
                                        </div>
                                        <ul class="badges">
                                            <li v-for="index in 50" :key="index" :class="{ 'orange-badge': link.joined >= index && link.active < index && link.joined > 25, 'blue-badge': link.active >= index && link.joined > 25 }"><i class="fas fa-award"></i></li>
                                            <li class="earned-dollar" v-if="link.active >= 50"><i class="fas fa-gift"></i> $200</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <div class="card_info">
                                            <p>Refer 100 Qualified Trials: get an extra $500 Thrive Dollars when you refer 100 coaches who start a Thrive Coach trial and complete at least 25 days. Each trial started will be orange. After 25 days the ribbon will turn blue and count toward your bonus. When your card is filled your $500 bonus will be added to your account and you will get your $500 badge and be qualified as a super-affiliate.</p>
                                            <img src="@/assets/images/logo.svg" alt=""/>
                                        </div>
                                        <ul class="badges">
                                            <li v-for="index in 100" :key="index" :class="{ 'orange-badge': link.joined >= index && link.active < index && link.joined > 50, 'blue-badge': link.active >= index && link.joined > 50 }"><i class="fas fa-award"></i></li>
                                            <li class="earned-dollar" v-if="link.active >= 100"><i class="fas fa-gift"></i> $500</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="section_content">
                            <div class="headline">
                                <h2><img src="@/assets/images/trophy-alt.svg">Leaderboard</h2>
                                <h4>Cheer on your fellow coaches</h4>
                            </div>
                            <div class="filter_fld">
                                <div class="multiselect-container">
                                    <multiselect v-model="leaderboardFilter.filter" :options="leaderboardFilterOptions"></multiselect>
                                </div>
                                <label for="show-all-contacts" class="switch_option capsule_btn p-0">
                                    <h5 class="capitalize">{{ leaderboardFilter.type }}</h5>
                                    <input type="checkbox" id="show-all-contacts" v-model="leaderboardFilter.type" true-value="qualified" false-value="trials" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div class="leader_list small">
                                <ul>
                                    <li>
                                        <label>Rank</label>
                                        <div class="label"><span>Participant</span><span>Referrals</span></div>
                                    </li>
                                    <li class="loader" v-if="leaderboardLoader"><quote-loader /></li>
                                    <li v-else v-for="(leaderboard, l) of leaderboards" :key="l">
                                        <label># {{ l + 1 }}</label>
                                        <div class="coach">{{ leaderboard.coach ? leaderboard.coach.full_name : '&nbsp;' }} <span>{{ leaderboard.referrals }}</span></div>
                                    </li>
                                    <li class="text-center" v-if="leaderboards.length == 0">
                                        <div class="empty_box">
                                            <img src="@/assets/images/empty_state.svg">
                                            <h4>No participant found!</h4>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="section_content w-100" v-if="tab === 'faq'">
                        <div class="section_container">
                            <div class="headline">
                                <h2>Program FAQ</h2>
                                <h4>Have Questions? We are here to help.</h4>
                            </div>
                            <div class="faq_list">
                                <ul>
                                    <li v-for="(faq, f) of faqs" :key="f" :class="{'show' : f == 0}">
                                        <h4 @click="accordion($event)">{{faq.question}} <i class="fas fa-chevron-down"></i></h4>
                                        <div class="answer_area">
                                            <div class="answer-text" v-html="faq.answer"></div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="term_list">
                                <div class="headline">
                                    <h2>Program Terms</h2>
                                </div>
                                <ol>
                                    <li v-for="(term, t) of terms" :key="t">
                                        {{ term.term }}
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { copyText } from 'vue3-clipboard'
    import { mapState, mapActions } from 'vuex'

    import Toastr from '@/utils/Toastr'

    export default {
        name: 'Refer & Earn',

        data () {
            return {
                tab: 'share',
                show_balance: false,
                leaderboardFilterOptions: [
                    { value: 'all-time', label: 'All Time' },
                    { value: 'current-month', label: 'Current Month' },
                    { value: 'previous-month', label: 'Previous Month' }
                ],
                leaderboardFilter: {
                    filter: 'all-time',
                    type: 'qualified',
                },
                historyParams: {
                    per_page: 10,
                    page: 1,
                },
                terms:[
                    {
                        term: 'Self-referrals are not allowed. Cheating will get you permanently banned from our referral program.'
                    },
                    {
                        term: 'We reserve the right to disqualify rewards earned through fraudulent, illegal, or abusive methods.'
                    },
                    {
                        term: 'We will only count referrals on links that are automatically tracked and reported by our system. We will not credit a referral if someone says they signed up or someone says they entered a referral code and it was not tracked by our system.'
                    },
                    {
                        term: 'We reserve the right to change the terms of service for our referral program at any time.'
                    },
                    {
                        term: 'You may not use our referral program for any illegal or unauthorized purpose. You must not violate any laws in your jurisdiction.'
                    },
                    {
                        term: 'Referrers may not bid on keywords involving the Thrive Coach brand name, including "Thrive Coach", "ThriveCoach", and "Thrive Coach Software".'
                    },
                    {
                        term: 'Referrers may not send paid traffic directly to thrivecoach.io or thrivecoach.com from any digital ad platform. Referrers may send paid traffic to a website or content that the referrer owns.'
                    },
                ]
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        watch: {
            leaderboardFilter: {
                handler (filter) {
                    const vm = this;

                    vm.getLeaderboards(filter);
                },
                deep: true
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            link: state => state.referralModule.link,
            faqs: state => state.referralModule.faqs,
            histories: state => state.referralModule.histories,
            historyLoader: state => state.referralModule.historyLoader,
            leaderboards: state => state.referralModule.leaderboards,
            leaderboardLoader: state => state.referralModule.leaderboardLoader,
        }),

        mounted () {
            const vm = this;

            vm.getLink();
            vm.getFaqs();
            vm.getHistories(vm.historyParams);
            vm.getLeaderboards(vm.leaderboardFilter);
        },

        methods: {
            ...mapActions({
                getLink: 'referralModule/getLink',
                getFaqs: 'referralModule/getFaqs',
                getHistories: 'referralModule/getHistories',
                getLeaderboards: 'referralModule/getLeaderboards',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            accordion (e) {
                let el = e.currentTarget.parentElement;
                let allEl = document.querySelectorAll('.faq_list li');

                if (el.classList.contains('show')) {
                    el.classList.remove('show');
                } else {
                    for(let i = 0; i < allEl.length; i++){
                        allEl[i].classList.remove('show');
                    }
                    el.classList.add('show');
                }
            },

            handlePagination (page) {
                const vm = this;

                vm.historyParams.page = page;
                vm.getHistories(vm.historyParams);
            },

            copyLink (link) {
                const vm = this;

                copyText(link, undefined, (error, event) => {
                    if (error) {
                        Toastr.error('Failed to copy link!');
                    } else {
                        Toastr.success('Link copied successfully!');
                    }
                });
            },

            toggleSectionbar () {
                const leftSidebar = document.querySelector('.global_setting');

                if (leftSidebar.classList.contains('show_m_sidebar')) {
                    leftSidebar.classList.remove('show_m_sidebar');
                } else {
                    leftSidebar.classList.add('show_m_sidebar');
                }
            },
        },
    };
</script>

<style scoped>
    .headline h2 {
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
        color: #121525;
        margin: 0;
        padding: 7px 0;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .headline h2 i {
        font-size: 20px;
        padding-right: 12px;
    }
    .headline h2 img {
        max-height: 20px;
        width: auto;
        padding-right: 12px;
        filter: grayscale(1);
    }

    .headline h4 {
        font-size: 15px;
        line-height: 22px;
        font-weight: 400;
        color: #5a5a5a;
        margin: 0;
        padding: 7px 0;
        text-align: center;
    }

    .top_area {
        position: relative;
        /* padding-right: 250px; */
    }

    .acc_balance {
        background: #fff;
        border-radius: 10px;
        border: 1px solid #f5f5f5;
        box-shadow: 0 0 20px rgba(0,0,0,0.05);
        position: absolute;
        right: 0;
        top: 15px;
        width: 200px;
    }

    .acc_balance h4 {
        font-size: 13px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        display: flex;
        align-items: center;
        padding: 12px 15px;
        border-bottom: 1px solid #e9e9e9;
    }

    .acc_balance h4 img {
        max-height: 20px;
        height: auto;
        padding-right: 10px;
    }

    .acc_balance h1 {
        font-size: 25px;
        line-height: 30px;
        color: #f2a31d;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 10px;
    }

    .acc_balance h1 span {
        font-size: 13px;
        line-height: 18px;
        padding-right: 10px;
        color: #5a5a5a;
    }

    .referal_link {
        max-width: 600px;
        width: 100%;
        margin: 40px auto 60px auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .referal_link h5 {
        font-size: 16px;
        line-height: 23px;
        font-weight: 400;
        color: #121525;
        padding-bottom: 15px;
    }

    .referal_link .link_area {
        border: 1px solid #e9e9e9;
        background: #fff;
        width: 100%;
        padding: 0 130px 0 0;
        border-radius: 25px;
        position: relative;
    }

    .referal_link .link_area input[type=text] {
        width: 100%;
        height: 48px;
        padding: 0 30px;
        background: transparent;
    }

    .referal_link .link_area .copy_btn {
        width: 130px;
        height: 48px;
        position: absolute;
        top: 0;
        right: 0;
        background: #2f7eed;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-size: 13px;
        line-height: 16px;
        color: #fff;
        font-weight: 400;
        border-radius: 0 25px 25px 0;
        padding: 10px;
        cursor: pointer;
    }

    .referal_link .link_area .copy_btn i {
        font-size: 16px;
    }

    .referal_link .btn_list {
        margin-top: 20px;
    }

    .other_info {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 15px;
        padding-bottom: 30px;
    }

    .other_info h4 {
        font-size: 15px;
        line-height: 21px;
        font-weight: 400;
        color: #121525;
    }

    .btn_list {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
    }

    .btn_list li a {
        font-size: 11px;
        line-height: 14px;
        padding: 6px 16px;
        background: #2f7eed;
        border-radius: 15px;
        color: #fff;
        font-weight: 400;
        display: flex;
        text-decoration: none;
        align-items: center;
        cursor: pointer;
    }

    .btn_list li a i {
        font-size: 13px;
        margin-right: 7px;
    }

    .result_wpr {
        max-width: 1200px;
        width: 100%;
        margin: 0 auto 0 auto;
        /* width: calc(100% - 100px); */
        overflow-x: scroll;
    }
    .result_wpr::-webkit-scrollbar{
        height: 3px;
        display: block;
    }
    .result_wpr::-webkit-scrollbar-thumb{
        background: #dbdbdb;
        border-radius: 2px;
    }

    .result_wpr table {
        border-radius: 14px;
        min-width: 590px;
    }

    .result_wpr table thead {
        border-right: 0;
        border-left: 0;
    }

    .result_wpr table td{
        padding: 18px;
        text-align: center;
        font-weight: 400;
    }
    .result_wpr table th {
        font-size: 11px;
        line-height: 16px;
        padding: 10px 18px;
        text-align: center;
    }

    .result_wpr table td:first-child,
    .result_wpr table th:first-child {
        text-align: left;
    }

    /* Rewards */

    .reward_list {
        margin: 0;
        max-height: calc(100vh - 185px);
        overflow-y: auto;
    }

    .reward_list::-webkit-scrollbar {
        display: none;
    }

    .reward_list>ul {
        display: flex;
        flex-direction: column;
        max-width: 450px;
        margin: 0 auto 30px auto;
    }

    .reward_list>ul>li {
        background: #ffff;
        border-radius: 10px;
        margin-bottom: 30px;
    }

    .reward_list ul li .card_info {
        padding: 25px 25px 5px 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
    }

    .reward_list li .card_info img {
        max-height: 40px;
        width: auto;
    }

    .reward_list li .card_info p {
        font-size: 13px;
        line-height: 21px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .reward_list .badges {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 20px 30px;
        gap: 13px;
    }

    .reward_list .badges li {
        font-size: 20px;
        color: #999;
    }
    .global_setting .tabs_content .content_area{
        padding: 0;
    }
    .content_row {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        height: calc(100vh - 15px);
    }

    .global_setting .tabs_content .section_content{
        padding: 20px 30px 0 30px;
    }
    .global_setting .tabs_content .content_row .section_content:last-of-type{
        border-left: 1px solid #e9e9e9;
    }

    .content_row .headline {
        margin-bottom: 30px;
    }

    /* .content_row .section_content{
            padding: 0 40px;
        } */

    .filter_fld {
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 450px;
        padding-bottom: 30px;
    }

    .filter_fld .multiselect-container {
        width: 150px;
    }

    .filter_fld .multiselect {
        border-radius: 15px;
        min-height: 26px;
        font-size: 13px;
        box-shadow: none;
        border: 0;
    }

    .filter_fld :deep(.multiselect-clear) {
        display: none;
    }

    .filter_fld :deep(.multiselect .multiselect-single-label) {
        padding-right: 35px;
        width: 100%;
        justify-content: center;
    }

    .filter_fld :deep(.multiselect-dropdown) {
        overflow-y: overlay;
    }

    .filter_fld :deep(.multiselect-dropdown::-webkit-scrollbar) {
        width: 4px;
    }

    .filter_fld :deep(.multiselect-dropdown::-webkit-scrollbar-thumb) {
        background: #dbdbdb;
        border-radius: 2px;
    }

    .filter_fld :deep(.multiselect-dropdown ul.multiselect-options li) {
        font-size: 11px;
        line-height: 15px;
    }

    .leader_list {
        margin: 0;
        height: calc(100vh - 185px);
        max-height: calc(100vh - 185px);
        overflow-y: auto;
    }

    .leader_list::-webkit-scrollbar {
        display: none;
    }

    .leader_list ul {
        background: #fff;
        border-radius: 8px;
        max-width: 450px;
        margin: 0 auto 50px auto;
    }

    .leader_list li {
        display: flex;
        align-items: center;
        padding: 12px 5px;
        border-bottom: 1px solid #f5f5f5;
    }

    .leader_list li label {
        width: 60px;
        padding: 0 10px;
        flex-grow: 0;
        font-size: 13px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
    }

    .leader_list li .label {
        flex: 1 1 auto;
        padding: 0 10px;
        font-size: 11px;
        line-height: 16px;
        color: #121525;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
    }

    .leader_list li .coach {
        flex: 1 1 auto;
        padding: 0 10px;
        background: #fff;
        font-size: 13px;
        line-height: 17px;
        font-weight: 400;
        color: #5a5a5a;
        border-radius: 3px;
        display: flex;
        justify-content: space-between;
    }

    .leader_list ul li.loader {
        min-height: 50vh;
        justify-content: center;
    }
    .leader_list li:first-child {
        position: sticky;
        top: 0;
        margin: 0;
        padding: 10px 5px;
        background: #fff;
        border-bottom: 1px solid #e9e9e9;
        border-radius: 8px 8px 0 0;
        z-index: 1;
    }
    .leader_list li:first-child label{
        font-size: 11px;
        font-weight: 500;
        color: #121525;
    }

    .section_container {
        max-width: 700px;
        margin: 0 auto;
    }

    .faq_list {
        margin: 30px 0 50px;
    }

    .faq_list ul {
        display: flex;
        flex-direction: column;
    }

    .faq_list ul li {
        background: #fff;
        border-radius: 8px;
        margin-bottom: 30px;
    }

    .faq_list ul li h4 {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        padding: 20px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }

    .faq_list ul li h4 i {
        color: #7e7e7e;
        transition: all 0.3s ease-in-out;
    }

    .faq_list ul li .answer_area {
        max-height: 0;
        height: 100%;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }

    .faq_list ul li p {
        padding: 20px 30px 30px 30px;
        border-top: 1px solid #f5f5f5;
        font-size: 13px;
        line-height: 21px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .faq_list ul li.show h4 i {
        transform: rotate(-180deg);
    }

    .faq_list ul li.show .answer_area {
        max-height: 500px;
    }
    .term_list{
        background: #fff;
        border-radius: 8px;
        padding: 20px 30px;
        margin-bottom: 50px;
    }
    .term_list ol {
        padding: 25px 0 0 15px;
    }

    .term_list ol li {
        padding: 0 0 25px 15px;
        list-style-type: decimal;
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        color: #121525;
    }

    .result_wpr table td:first-child,
    .result_wpr table th:first-child {
        width: auto;
    }

    .pagination {
        width: 100%;
    }
    .table_footer{
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
    }

    li.text-center {
        justify-content: center;
    }

    .pagination div.page-info {
        font-size: 14px;
        line-height: 20px;
    }

    .reward_list .badges li.blue-badge {
        color: #2f7eed;
    }

    .reward_list .badges li.orange-badge {
        color: #f2a31e;
    }

    .reward_list ul.badges {
        position: relative;
        width: 100%;
    }

    .reward_list .badges li.earned-dollar {
        color: #30C13E;
        font-weight: 600;
        position: absolute;
        right: 30px;
        bottom: 20px;
    }

    .answer_area .answer-text {
        padding: 20px 30px;
        border-top: 1px solid #f5f5f5;
    }

    :deep(.answer_area .answer-text p) {
        font-size: 13px;
        line-height: 21px;
        font-weight: 400;
        color: #5a5a5a;
    }

    :deep(.answer_area .answer-text h4) {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
    }

    :deep(.answer_area .answer-text ul) {
        margin-top: 10px;
    }

    :deep(.answer_area .answer-text li) {
        list-style-type: disc;
        margin-left: 30px;
        font-size: 13px;
        line-height: 21px;
        font-weight: 400;
        color: #5a5a5a;
    }
    .global_setting button.reward_balance {
        display: none;
        position: fixed;
        right: 10px;
        top: 45px;
        line-height: 0;
        z-index: 4;
        transition: all .3s ease-in-out;
        cursor: pointer;
    }
    .global_setting button.reward_balance img{
        width: 20px;
        height: auto;
    }

    @media(max-width: 1199px){
        .top_area{
            padding: 0;
        }
        .result_wpr, .pagination{
            width: 100%;
        }
        .global_setting button.toggle_bar{
            position: fixed;
        }
        .global_setting button.reward_balance{
            display: block;
        }
        .acc_balance{
            position: fixed;
            top: -115px;
            right: 40px;
            transition: all 0.3s ease-in-out;
        }
        .acc_balance h4{
            font-size: 13px;
            line-height: 18px;
            color: #121525;
            font-weight: 500;
            display: flex;
            align-items: center;
            padding: 12px 15px;
            border-bottom: 1px solid #f5f5f5;
        }
        .acc_balance h4 img{
            max-height: 18px;
            height: auto;
            padding-right: 10px;
        }
        .acc_balance.show{
            top: 30px;
        }
    }
    @media(max-width: 767px){
        .result_wpr table th{
            padding: 10px;
        }
        .result_wpr table td{
            padding: 15px 10px;
        }
        .referal_link .link_area input[type=text] {
            padding: 0 10px 0 20px;
        }
        .reward_list{
            max-height: none;
        }
        .global_setting .tabs_content .content_row .section_content{
            width: 100%;
            padding: 30px;
        }
        .global_setting .tabs_content .content_row .section_content:last-of-type{
            border-left: 0;
            border-top: 1px solid #e9e9e9;
        }
    }
    @media(max-width: 499px){
        .referal_link .link_area .copy_btn{
            width: 50px;
            height: 43px;
        }
        .referal_link .link_area .copy_btn span{
            display: none;
        }
        .referal_link .link_area{
            padding-right: 50px;
        }
        .referal_link .link_area input[type=text]{
            height: 43px;
        }
    }
</style>
